<template>

	<div class="wrap p-phonics-book">
		<!-- header -->
		<header class="header-step">
			<div class="head-left">
				<button type="button" class="btn-back" @click="$router.go(-1)"><span class="blind">이전 페이지</span></button>
			</div>
			<div class="head-center">
				<div class="head-tit">
					<span class="page-tit" v-if="eduMain != null">Fun Phonics - {{ eduMain.eduTitle }}</span>
				</div>
			</div>
			<div class="head-right"></div>
		</header>
		<!-- container -->
		<div class="container">
			<div class="content">
				<div class="swiper book-list">
					<ul class="swiper-wrapper">

						<li class="swiper-slide" v-for="(item, index) in contentList">
							<a href="javascript:;" @click="doAnswer(item.emIdx, index)" v-if="item.qstMovVO != null">
								<em class="book-vol">{{ eduMain.shortTitle }}</em>
								<div class="thumb" :style="'background-image: url(' + item.qstMovVO.movThumb + ')'"></div>
								<strong class="book-tit">{{ item.qstMovVO.movTitle }}</strong>
							</a>
						</li>

					</ul>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

export default {
	name: "contentList",
	data() {

		var swiper = () => {
			new this.Swiper(".p-phonics-book .book-list", {
				slidesPerView: "auto",
				spaceBetween: 20,
				freeMode: true,
				breakpoints: {
					1024: {
						centeredSlides: true,
					},
				},
				watchSlidesProgress: true
			});
		};

		return {

			swiper,

			level: this.$route.query.level,
			emIdx: this.$route.query.emIdx,
			eduMain: null,
			contentList: [],
			answerList: [],
		}
	},
	created () {

		this.$eventBus.$emit("startProgress")
		this.getEduMain( this.emIdx, this.getLoginId() );
	},
	mounted() {
	},
	methods: {

		getStudent: function ( emIdx, userId ) {

			var self = this;
			self.completeContentCnt = 0;

			this.axiosCaller.get(  this, this.APIs.EDU_STUDENT + "/", {
				emIdx: emIdx,
				userId: userId,
			}, function (res) {

				const result = res.data
				//console.log(result)

				if ( result.status === "ok" ) {
					self.eduStudent = result.eduStudentVO;
					self.answerList = result.answerList;

					/**
					 * 사용자의 이수 컨텐츠 합
					 */
					var index = 0;
					self.answerList.forEach(ans => {

						if ( !ans.isComplete ) {
							self.isEnableComplete = false
						}

						if ( ans.contentType === 'tst' ) {
							self.completeContentCnt += ans.tstSolveCount;
						} else if ( ans.contentType === 'mov' ) {

							if ( ans.isComplete || Math.floor(ans.ansMovVO.currentTimes / 60) >= self.eduMain.contentList[index].qstMovVO.playMinutes ) {
								/**
								 * 영상이수완료 or 플레이시간이 필수플레이 시간보다 많을 경우
								 */
								self.completeContentCnt += self.eduMain.contentList[index].qstMovVO.playMinutes
							} else {
								/**
								 * 플레이 시간을 분단위로 추가
								 * @type {number}
								 */
								self.completeContentCnt += Math.floor(ans.ansMovVO.currentTimes / 60);
							}
						} else if ( ans.contentType === 'file' ) {
							if ( ans.isComplete ) {
								self.completeContentCnt += self.eduMain.contentList[index].qstFileVO.fileList.length;
							} else {
								self.completeContentCnt += ans.fileDownloadCount;
							}
						}
						index++;
					})

					//console.log("self.completeContentCnt ::::: " + self.completeContentCnt);
				} else {
					console.log(result.msg)
				}

			})

		},


		getEduMain: function (_emIdx, _userId) {

			var self = this

			self.$eventBus.$emit("startProgress")
			this.axiosCaller.get(this, this.APIs.EDU_MAIN + "/getEduMain", {
				emIdx : _emIdx,
				userId : _userId,
			}, async function (res) {
				self.$eventBus.$emit("doneProgress")

				const result = res.data
				console.log(result)
				if (result.status === "ok") {

					self.eduMain = result.eduMain
					self.contentList = self.eduMain.contentList

					// 현재 신청자 + 완료자 합계산
					self.finishCount = self.eduMain.requestCount + self.eduMain.completeCount;

					self.eduMain.playMinutes = 0;
					self.allContentCnt = 0;
					self.eduMain.contentList.forEach(cv => {

						// 교육 컨텐츠의 개수 합 + 기타 표출용 텍스트 정의
						if (cv.contentType === 'tst') {
							self.eduMain.isTest = true;
							self.eduMain.isTestTxt = "있음";
							self.allContentCnt += cv.qstTstList.length;
						} else if (cv.contentType === 'mov') {

							if (cv.qstMovVO == null) {
								self.swalUtils.fire("교육 구성이 진행중입니다.", "", "info", null, null)
									.then(t => {
										self.$router.go(-1);
									})
								return
							}

							self.eduMain.playMinutes += cv.qstMovVO.playMinutes;
							self.allContentCnt += cv.qstMovVO.playMinutes;
						} else if (cv.contentType === 'file') {

							if (cv.qstFileVO == null) {
								history.back();
								return
							}

							self.allContentCnt += cv.qstFileVO.fileList.length;
						}

						self.eduMain.introduce = self.$options.filters.nl2br(self.eduMain.introduce);
						self.eduMain.goal = self.$options.filters.nl2br(self.eduMain.goal);

						// 교육항목 저장
						self.eduContentList = self.eduMain.contentList

						// self.fadeIn('.__pop.createResult')

						// 현재 교육 관심, 좋아요 조회
						//self.getWishLike( self.emIdx, self.userId );

					})

					await self.doRequest();
					self.getStudent(self.emIdx, self.userId);

					self.$nextTick(() => {
						self.swiper()
						self.$eventBus.$emit("doneProgress")
					})

				}

				//console.log("allContentCnt ::::: " + self.allContentCnt)
			})

		},


		async doRequest () {

			if ( !await this.requireLogin(this) ) {
				return
			}


			var self = this
			return new Promise(resolve => {

				this.axiosCaller.put(this, this.APIs.EDU_STUDENT + "/request", {
					emIdx: this.eduMain.emIdx,
					userId: this.getLoginId(),
				}, function (res) {
					const result = res.data
					if ( result.status !== "ok" ) {
						self.swalUtils.gritter("관리자에게 문의해주세요.", result.msg, "error")
							.then(() => {
								self.$router.back()
							})
						return;
					} else {
						resolve( result )
					}
				})
			})

		},


		/**
		 * 학습 시작하기
		 * @param emIdx
		 */
		doAnswer: function (emIdx, index) {

			const url = this.APIs.BASE_URL + "/proceed/" + emIdx + "/" + btoa(this.getLoginId()) + "/?playStep=" + index;

			const temp = {
				url: url,
				target: "proceed",
				isCloseBTN: "Y",
			}
			this.appOpen( temp );
		},

	},
}
</script>

<style scoped>

</style>